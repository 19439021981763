import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import fonts, { weights } from '@utils/fonts';

import { H_PADDING_MOBILE } from '@utils/space';
import { Body, Caption as UnstyledCaption, Heading as UnstyledHeading } from '@components/type';

export const Blob = styled.div`
  ${(props) =>
    props.type === 'semi' &&
    css`
      position: absolute;
      top: 0;
      right: -70vh;
      height: 100vh;
      min-height: 100vh;
      width: 100vh;
      min-width: 100vh;
      border-radius: 50vh;
      background-color: ${(props) => getThemeStyles(props.colorTheme).background};

      ${mq.gtsm`
        top: -10vw;
        right: -40vw;
        height: 80vw;
        min-height: 80vw;
        width: 80vw;
        min-width: 80vw;
        border-radius: 40vw;
      `}

      ${(props) =>
        props.align === 'left' &&
        css`
          left: -70vh;
        `}
    `}

  ${(props) =>
    props.type === 'full' &&
    css`
      position: absolute;
      top: 0;
      right: -70vh;
      height: 100vh;
      min-height: 100vh;
      width: 100vh;
      min-width: 100vh;
      border-radius: 50vh;
      background-color: ${(props) => getThemeStyles(props.colorTheme).background};

      ${mq.gtsm`
        top: -10vw;
        right: -10vw;
        height: 44vw;
        min-height: 44vw;
        width: 44vw;
        min-width: 44vw;
        border-radius: 22vw;
      `}

      ${(props) =>
        props.align === 'left' &&
        css`
          left: -10vh;
        `}
    `}
`;

export const BlobWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 240px;
  left: 0;
  overflow: hidden;

  ${mq.gtsm`
    bottom: 60px;
  `}
`;

export const Caption = styled(UnstyledCaption)`
  margin-bottom: 24px;

  & span {
    display: inline-block;
    margin-right: 16px;
    color: ${(props) => getThemeStyles(props.colorTheme).text};
    font-weight: ${weights.BOLD};
    text-decoration: underline;
    cursor: pointer;
  }

  ${mq.gtmd`
    margin-bottom: 48px;
    // margin-top: -4rem;
  `}
`;

export const UpdatedDate = styled(UnstyledCaption)`
  display: inline-block;
  margin-left: 5px;
  //font-style: italic;
`;


export const Breadcrumb = styled(UnstyledCaption)`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  & > * {
    margin-right: 8px;
  }

  & > svg {
    height: 8px;
    width: 8px;
    fill: ${colors.N100};
    transform: rotate(-90deg);
  }

  ${mq.gtsm`
    margin-bottom: 48px;

    & > * {
      margin-right: 16px;
    }

    & > svg {
      height: 12px;
      width: 12px;
    }

    ${(props) => props.align === 'center' && css`
      justify-content: center;
    `}
  `}
`;

export const Content = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.hasCTA ? '-24px' : 0};
  padding: 0 ${H_PADDING_MOBILE} 8px;
  z-index: 1;
  text-align: ${(props) => (props.align === 'center' ? 'center' : 'left')};

  ${mq.gtmd`
    padding: 0 ${H_PADDING_MOBILE};
    margin: 0;
    flex: 1 0 ${(5 / 12) * 100}%;

    ${(props) =>
      props.align === 'center'
        ? css`
          flex: 0 1 ${7 / 12 * 100}%;
          margin: 0 auto;
        `
        : css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          `}

    ${(props) =>
      props.flipped
        ? css`
            padding-left: 80px;
          `
        : css`
            padding-right: 80px;
          `}
  `}

  ${mq.gtml`
    ${(props) =>
      props.align === 'left' ?
      css`
        padding-left: 0;
      ` : css`
        padding-right: 0;
      `}
  `}
`;

export const Description = styled(Body)`
  margin-bottom: 40px;
`;

export const EventData = styled.div`
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  font-family: ${props => fonts(props.theme.locale).SANS_CONDENSED};
  font-size: 26px;
  line-height: 30px;
  font-weight: ${weights.BOLD};
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
  color: ${(props) => getThemeStyles(props.colorTheme).text};
`;

export const Images = styled.div`
  position: relative;
  margin-bottom: 48px;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  ${(props) =>
    props.flipped &&
    css`
      & > div:first-of-type {
        margin-right: auto;
        margin-left: 0;
      }

      & > div:not(:first-of-type):last-of-type {
        right: 0;
        left: auto;
      }
    `}

  ${mq.gtmd`
    flex: 1 0 ${(7 / 12) * 100}%;
    margin-bottom: 0;
  `}
`;

export const ImageWrapper = styled.div`
  &:first-of-type {
    position: relative;
    width: 100%;
  }

  &:not(:first-of-type):last-of-type {
    position: absolute;
    bottom: -48px;
    left: 0;
    width: ${(7 / 12) * 100}%;
  }

  & span {
    display: inline-block;
    position: absolute;
    right: 0;
    font-weight: ${weights.BOLD};
    font-family: ${props => props.theme.fonts.SANS};
    font-size: 12px;

    ${props => props.flipped && css`
      right: auto;
      left: 0;
    `}
  }


  &:only-of-type {
    width: 100%;
  }

  &[data-has-video="true"] {
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      width: 128px;
      height: 128px;
      top: calc(50% - 64px);
      left: calc(50% - 64px);
      z-index: 1000;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0iI2ZmZiIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGg0OHY0OEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0yNCA0QzEyLjk1IDQgNCAxMi45NSA0IDI0czguOTUgMjAgMjAgMjAgMjAtOC45NSAyMC0yMFMzNS4wNSA0IDI0IDR6bS00IDI5VjE1bDEyIDktMTIgOXoiLz48L3N2Zz4=);
      background-size: cover;
    }
  }

  ${mq.gtmd`
    &:first-of-type {
      position: relative;
      width: ${(10 / 12) * 100}%;
      margin-left: auto;
    }

    &:not(:first-of-type):last-of-type {
      position: absolute;
      bottom: -16px;
      left: 0;
      width: ${(5 / 12) * 100}%;
    }

    &:only-of-type {
      width: 100%;
    }
  `}
`;

export const Inner = styled.div`
  position: relative;
  padding-top: 64px;
  z-index: 1;

  ${mq.gtmd`
    display: flex;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    padding: 192px 0 0;

    ${(props) =>
      props.flipped &&
      css`
        flex-direction: row-reverse;
      `}

    ${(props) =>
      props.noImages &&
      css`
        padding-top: 264px;
      `}
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
  padding-top: 48px;

  & > svg {
    position: absolute;
    top: 84px;
    right: 0;
    width: 80vw;
    height: auto;
    transform: translate(1%, 240px);

    & path {
      stroke-width: 6px;
    }
  }

  ${mq.gtsm`
    & > svg {
      top: 0;
      width: ${(props) => props.noImages ? '40vw' : '60vw'};
      transform: translate(1%, 150px);

      & path {
        stroke-width: 3px;
      }
    }
  `}

  ${(props) =>
    props.flipped
      ? css`
          & > svg {
            left: 0;
          }
        `
      : css`
          & > svg {
            right: 0;
          }
        `}
`;

export function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        fill: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        fill: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        fill: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        fill: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        fill: colors.Y200,
        text: colors.B500,
      };
  }
}
